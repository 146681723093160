<template>
  <div class="pageContainer">
    <h2 class="text-center">Sensors Data Graphs</h2>
    <div class="chartContainerStyle">
      <DataChart :chart-data="tempChartData" :options="tempChartOptions" :styles="chartStyle"/>
    </div>
    <div class="chartContainerStyle">
      <DataChart :chart-data="pressChartData" :options="pressChartOptions" :styles="chartStyle" />
    </div>
    <div class="chartContainerStyle">
      <DataChart :chart-data="humChartData" :options="humChartOptions" :styles="chartStyle" />
    </div>
  </div>
</template>

<script>
import DataChart from '../components/DataChart.js'

export default {
  name: 'DataGraphSensors',
  components: {
    DataChart,
  },

  data () {
    return {
      chartStyle: {
        height: '100%',
      },

      tempChartOptions: {
        events: [],
        animation: {
          duration: 0
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          }
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 20,
              suggestedMax: 25
            }
          }],
          xAxes: [{
            display: false
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      pressChartOptions: {
        events: [],
        animation: {
          duration: 0
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 10,
            boxHeight: 10,
          }
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 1000,
              suggestedMax: 1015
            }
          }],
          xAxes: [{
            display: false
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      humChartOptions: {
        events: [],
        animation: {
          duration: 0
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100
            }
          }],
          xAxes: [{
            display: false
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      tempChartData: null,
      pressChartData: null,
      humChartData: null,

      timer: null,
    }
  },

  async mounted() {
    if(this.$store.state.bleConnected) {
      this.timer = setInterval(this.loadData, 500)
    }
  },

  methods: {
    loadData() {
      let labels = []
      let tempData = this.$store.state.ambData.temp
      let tempExtData = this.$store.state.ambData.tempExt
      let pressData = this.$store.state.ambData.press
      let pressExtData = this.$store.state.ambData.pressExt
      let humData = this.$store.state.ambData.hum

      for(let i = 0; i < 25; i++) {
        labels.push(i+1)
      }

      this.tempChartData = {
        labels: labels,
        datasets: 
        [{
          label: 'Temperatur in °C (innen)',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: '#2c3e50',
          fill: false,
          data: tempData
        },
        {
          label: 'Temperatur in °C (außen)',
          lineTension: 0,
          borderWidth: 2,
          borderColor: 'red',
          pointBackgroundColor: 'red',
          backgroundColor: 'red',
          fill: false,
          data: tempExtData
        }]
      }

      this.pressChartData = {
        labels: labels,
        datasets: 
        [{
          label: 'Luftdruck in hPa (innen)',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: '2c3e50',
          fill: false,
          data: pressData
        },
        {
          label: 'Luftdruck in hPa (außen)',
          lineTension: 0,
          borderWidth: 2,
          borderColor: 'red',
          pointBackgroundColor: 'red',
          backgroundColor: 'red',
          fill: false,
          data: pressExtData
        }]
      }

      this.humChartData = {
        labels: labels,
        datasets: [{
          label: 'Rel. Luftfeuchte in %',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: humData
        }]
      }
    },

    cancelUpdates() {
      clearInterval(this.timer)
    },

  },

  async beforeDestroy() {
    if(this.$store.state.bleConnected) {
      this.cancelUpdates()
    }
  }
}
</script>

<style scoped>
.pageContainer {
  width: 100%;
  height: 100%;
  font-size: 0px;
}
.chartContainerStyle {
  display: inline-block;
  height:  30%;
  width: 95%;
  padding-bottom: 10px;
}
</style>